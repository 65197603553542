import React from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "@homesusa/layout";
import { FormContext, useGetFormErrors } from "@homesusa/form";
import { createPhone } from "core/services/phones/phones.service";
import { PhoneFormContextProps } from "../contexts/phone-form";
import { CustomError } from "core/hooks";

export const useCreatePhone = (): (() => Promise<void>) => {
  const navigate = useNavigate();
  const { formState } = React.useContext<PhoneFormContextProps>(FormContext);
  const { addAlert } = React.useContext(AppContext);
  const { hasErrors } = useGetFormErrors();
  const onSave = async (): Promise<void> => {
    if (hasErrors()) {
      return Promise.resolve();
    }

    await createPhone(formState)
      .then((id) => {
        addAlert({
          message: "The phone lead was successfully created",
          variant: "success"
        });
        navigate(`/lead-phones/${id}`);
      })
      .catch((customError: CustomError) => {
        addAlert({
          message: customError.response.data,
          variant: "error"
        });
      });
  };

  return onSave;
};
