export enum TransactionType {
  Sale = "sale",
  Lot = "lot",
  Lease = "lease"
}
export const TransactionTypeLabel = new Map<TransactionType, string>([
  [TransactionType.Sale, "For Sale"],
  [TransactionType.Lot, "For Lot"],
  [TransactionType.Lease, "For Lease"]
]);
