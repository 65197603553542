import React from "react";
import { FormContext, useGetFormErrors } from "@homesusa/form";
import { AppContext } from "@homesusa/layout";
import { isValidGuid } from "@homesusa/core";
import { PhoneContext, PhoneFormContextProps } from "../contexts";
import { updatePhone } from "core/services/phones/phones.service";

export const useUpdatePhone = (): (() => Promise<void>) => {
  const { getPhone } = React.useContext(PhoneContext);
  const { formState } = React.useContext<PhoneFormContextProps>(FormContext);
  const { addAlert } = React.useContext(AppContext);
  const { hasErrors } = useGetFormErrors();

  const onSave = async (): Promise<void> => {
    if (hasErrors()) {
      return Promise.resolve();
    }

    if (!isValidGuid(formState.id)) {
      return Promise.resolve();
    }

    return updatePhone(formState).then(() => {
      addAlert({
        message: "The phone lead was successfully updated",
        variant: "success"
      });
      getPhone();
    });
  };

  return onSave;
};
