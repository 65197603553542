import React from "react";
import { Save as SaveIcon } from "@mui/icons-material";
import { useHasRole } from "@homesusa/auth";
import { SubmitButton } from "@homesusa/core";
import { DeleteEmailButton } from "./delete-email-button.component";
import { useUpdateEmail } from "modules/emails/hooks";

export function EmailActionsGroup(): JSX.Element {
  const { isMlsAdministrator } = useHasRole();
  const onSave = useUpdateEmail();

  return (
    <React.Fragment>
      {isMlsAdministrator && (
        <SubmitButton
          onClick={onSave}
          buttonProps={{
            variant: "contained",
            color: "primary",
            startIcon: <SaveIcon />
          }}
        >
          Save
        </SubmitButton>
      )}
      {isMlsAdministrator && <DeleteEmailButton />}
    </React.Fragment>
  );
}
