import React from "react";
import { FormProvider, FormRules } from "@homesusa/form";
import { EmailDataForm } from "core/interfaces";
function EmailFormProvider({
  children,
  rules,
  initialData
}: {
  children: React.ReactNode;
  rules: FormRules;
  initialData?: EmailDataForm;
}): JSX.Element {
  return (
    <FormProvider<EmailDataForm>
      initialFormState={initialData}
      initialFormRules={rules}
    >
      {children}
    </FormProvider>
  );
}
export { EmailFormProvider };
