import React from "react";
import { FormContext, useGetFormErrors } from "@homesusa/form";
import { AppContext } from "@homesusa/layout";
import { isValidGuid } from "@homesusa/core";
import { EmailContext, EmailFormContextProps } from "../contexts";
import { updateEmail } from "core/services/emails/emails.service";

export const useUpdateEmail = (): (() => Promise<void>) => {
  const { getEmail } = React.useContext(EmailContext);
  const { formState } = React.useContext<EmailFormContextProps>(FormContext);
  const { addAlert } = React.useContext(AppContext);
  const { hasErrors } = useGetFormErrors();

  const onSave = async (): Promise<void> => {
    if (hasErrors()) {
      return Promise.resolve();
    }

    if (!isValidGuid(formState.id)) {
      return Promise.resolve();
    }

    return updateEmail(formState).then(() => {
      addAlert({
        message: "The email lead was successfully updated",
        variant: "success"
      });
      getEmail();
    });
  };

  return onSave;
};
