import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { isValidGuid } from "@homesusa/core";
import { PhoneContext } from "modules/phones/contexts";
import { useDeletePhone } from "modules/phones/hooks";

export function DeletePhoneButton(): JSX.Element {
  const { phone } = React.useContext(PhoneContext);
  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = (): void => setShowModal(false);
  const handleShowModal = (): void => setShowModal(true);
  const onDelete = useDeletePhone();

  const isValidPhoneId = React.useMemo((): boolean => {
    return !!phone && isValidGuid(phone.id);
  }, [phone]);

  return (
    <React.Fragment>
      <Button
        onClick={handleShowModal}
        disabled={!isValidPhoneId}
        startIcon={<Delete />}
        className="danger"
      >
        Delete
      </Button>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Delete Phone</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this phone?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => {
              handleCloseModal();
              onDelete();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
