export enum EmailSource {
  ApartmentGuide = "apartmentGuide",
  ApartmentList = "apartmentList",
  Apartments = "apartments",
  AborDotCom = "aborDotCom",
  HarDotCom = "harDotCom",
  HomesUSA = "homesUSA",
  Homes = "homes",
  Ntreis = "ntreis",
  Point2 = "point2",
  PropertyPanorama = "propertyPanorama",
  PropertyShark = "propertyShark",
  Realtor = "realtor",
  SaborDotCom = "saborDotCom",
  ShowingTime = "showingTime",
  Trulia = "trulia",
  Zillow = "zillow"
}
export const EmailSourceLabel = new Map<EmailSource, string>([
  [EmailSource.ApartmentGuide, "Apartment Guide"],
  [EmailSource.ApartmentList, "Apartment List"],
  [EmailSource.Apartments, "Apartments"],
  [EmailSource.AborDotCom, "Abor.com"],
  [EmailSource.HarDotCom, "Har.com"],
  [EmailSource.HomesUSA, "HomesUSA"],
  [EmailSource.Homes, "Homes"],
  [EmailSource.Ntreis, "Ntreis"],
  [EmailSource.Point2, "Point 2"],
  [EmailSource.PropertyPanorama, "Property Panorama"],
  [EmailSource.PropertyShark, "Property Shark"],
  [EmailSource.Realtor, "Realtor"],
  [EmailSource.SaborDotCom, "Sabor.com"],
  [EmailSource.ShowingTime, "Showing Time"],
  [EmailSource.Trulia, "Trulia"],
  [EmailSource.Zillow, "Zillow"]
]);
