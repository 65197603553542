import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { RoleUser } from "@homesusa/core";
import { useHasRole } from "@homesusa/auth";
import {
  AppContext,
  appPublicRoutesConfig,
  PrivateRoute
} from "@homesusa/layout";
import { appState } from "../app-state";
import PhoneDetails from "modules/phones/pages/phone-detail.component";
import EmailDetails from "modules/emails/pages/email-detail.component";

const LeadPhonesGrid = React.lazy(
  () => import("modules/phones/pages/phones-grid.component")
);
const LeadEmailsGrid = React.lazy(
  () => import("modules/emails/pages/emails-grid.component")
);
function AppRoutes(): JSX.Element {
  const { appStateDispatch } = React.useContext(AppContext);
  const { isMlsAdministrator } = useHasRole();

  React.useEffect(() => {
    const sidebarItems = appState.layout.sidebar.items;
    appStateDispatch({ type: "UpdateSidebarItem", payload: sidebarItems });
  }, [appStateDispatch, isMlsAdministrator]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        {appPublicRoutesConfig.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route
          path="/lead-phones"
          element={
            <PrivateRoute
              allowedUserRoles={[RoleUser.MLSAdministrator, RoleUser.User]}
            />
          }
        >
          <Route path=":id" element={<PhoneDetails />} />
          <Route index element={<LeadPhonesGrid />} />
        </Route>
        <Route
          path="/lead-emails"
          element={
            <PrivateRoute
              allowedUserRoles={[RoleUser.MLSAdministrator, RoleUser.User]}
            />
          }
        >
          <Route path=":id" element={<EmailDetails />} />
          <Route index element={<LeadEmailsGrid />} />
        </Route>
        <Route path="/" element={<Navigate to="/lead-phones" />} />
        <Route path="*" element={<p>not found</p>} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
