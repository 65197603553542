import React from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "@homesusa/layout";
import { FormContext, useGetFormErrors } from "@homesusa/form";
import { createEmail } from "core/services/emails/emails.service";
import { EmailFormContextProps } from "../contexts/email-form";
import { CustomError } from "core/hooks";

export const useCreateEmail = (): (() => Promise<void>) => {
  const navigate = useNavigate();
  const { formState } = React.useContext<EmailFormContextProps>(FormContext);
  const { addAlert } = React.useContext(AppContext);
  const { hasErrors } = useGetFormErrors();

  const onSave = async (): Promise<void> => {
    if (hasErrors()) {
      return Promise.resolve();
    }

    await createEmail(formState)
      .then((id) => {
        addAlert({
          message: "The email lead was successfully created",
          variant: "success"
        });
        navigate(`/lead-emails/${id}`);
      })
      .catch((customError: CustomError) => {
        addAlert({
          message: customError.response.data,
          variant: "error"
        });
      });
  };

  return onSave;
};
