import { Leaderboard as LeaderboardIcon } from "@mui/icons-material";
import { AppState, appStateDefault, AppType } from "@homesusa/layout";

enum SidebarItemType {
  LeadManager = "leadManager"
}

export const appState: AppState = {
  ...appStateDefault,
  currentApp: AppType.LeadManagement,
  layout: {
    ...appStateDefault.layout,
    sidebar: {
      ...appStateDefault.layout.sidebar,
      selected: SidebarItemType.LeadManager,
      items: {
        [SidebarItemType.LeadManager]: {
          title: "Lead Manager",
          icon: LeaderboardIcon,
          items: [
            { title: "Phone", endpoint: "/lead-phones" },
            { title: "Email", endpoint: "/lead-emails" }
          ]
        }
      }
    }
  }
};
