import {
  getOptionsFromEnumAndMap,
  MarketCode,
  MarketCodeLabel
} from "@homesusa/core";
import { FormRules, zipCodeRule } from "@homesusa/form";
import { TransactionType, TransactionTypeLabel } from "core/enum";

export const commonInfoRules: FormRules = {
  transactionType: {
    label: "Transaction Type",
    required: true,
    options: getOptionsFromEnumAndMap(
      Object.values(TransactionType),
      TransactionTypeLabel
    )
  },
  mlsNum: {
    label: "MLS #",
    required: true
  },
  market: {
    label: "Market",
    required: true,
    options: getOptionsFromEnumAndMap(
      Object.values(MarketCode),
      MarketCodeLabel
    )
  },
  companyName: {
    label: "Builder"
  },
  subdivision: {
    label: "Subdivision",
    required: true
  },
  address: {
    label: "Address",
    required: true
  },
  city: {
    label: "City",
    required: true
  },
  transferedOn: {
    label: "Date Created",
    required: true
  },
  senderFirstName: {
    label: "First Name",
    required: true,
    maxLength: 50
  },
  senderLastName: {
    label: "Last Name",
    required: true
  },
  zipCode: {
    ...zipCodeRule,
    required: true
  }
};
