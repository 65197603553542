import axios from "axios";
import { GridResponse } from "@homesusa/grid-table";
import { Phone, PhoneDataForm } from "core/interfaces";
import { FetchDataGrid } from "core/interfaces/services";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/`;

const getActivePhones = async (
  params: FetchDataGrid
): Promise<GridResponse<Phone>> => {
  const response = await axios.get<GridResponse<Phone>>(
    `${apiUrl}phone-leads`,
    {
      params
    }
  );
  return response.data;
};

const createPhone = async (phone: PhoneDataForm): Promise<string> => {
  const response = await axios.post(`${apiUrl}phone-leads`, phone);
  return response.data;
};

const getPhone = async (id: string): Promise<PhoneDataForm> => {
  const response = await axios.get(`${apiUrl}phone-leads/${id}`);
  return response.data as PhoneDataForm;
};
const updatePhone = async (phone: PhoneDataForm): Promise<PhoneDataForm> => {
  const { id, ...phoneDataForm } = phone;
  const response = await axios.put(`${apiUrl}phone-leads/${id}`, phoneDataForm);
  return response.data;
};
const deletePhone = async (id: string): Promise<void> =>
  axios.delete(`${apiUrl}phone-leads/${id}`).then(() => {
    return Promise.resolve();
  });
export { getActivePhones, createPhone, getPhone, updatePhone, deletePhone };
